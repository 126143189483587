<template>
  <div class="blog relative landing-page-wrapper new-langing-ui">
    <landing-page-header />
    <content-with-sidebar class="blog-wrapper content-info no-white-space">
      <!-- blogs -->
      <b-row class="blog-list-wrapper">
        <b-col
          v-for="(blog, index) in blogList"
          :key="index"
          md="4"
        >
          <b-card
            tag="article"
            class="articles-wrapper"
            no-body
          >
            <b-link :to="{ path: getViewUrl(blog) }" class="blog-image-height">
              <b-img
                :src="$helpers.imageHelper(blog.picture)"
                class="card-img-top"
                @error="$helpers.imageError"
              />
            </b-link>
            <b-card-body class="pt-0">
              <b-card-title class="d-flex align-items-center justify-content-between">
                <b-link :to="{ path: getViewUrl(blog) }" class="text-body-heading">
                  {{ blog.title }}
                </b-link>
              </b-card-title>
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                  class="mr-50"
                >
                  <b-avatar
                    href="javascript:void(0)"
                    size="24"
                    :src="$helpers.imageHelper(blog.user_picture)"
                  />
                </b-media-aside>
                <b-media-body>
                  <small
                    v-if="blog.user_fullname"
                    class="text-muted mr-50"
                  >{{ $t('by') }}</small>
                  <small v-if="blog.user_fullname">
                    <b-link class="text-body">{{ blog.user_fullname }}</b-link>
                  </small>
                </b-media-body>
              </b-media>
              <!-- <div
                v-if="blog.tags"
                class="my-1 py-25 tags-wrapper-blogs"
              >
                <b-link
                  v-for="(tag, count) in blog.tags"
                  :key="count"
                >
                  <b-badge
                    pill
                    class="mr-75"
                    :variant="tagsColor(tag)"
                  >
                    {{ tag }}
                  </b-badge>
                </b-link>
              </div> -->
              <b-card-text
                class="blog-content-truncate mt-2 mb-0"
                v-html="blog.description"
              />
              <hr>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex">
                  <b-link class="mr-1">
                    <div class="d-flex align-items-center text-body">
                      <feather-icon
                        icon="MessageSquareIcon"
                        class="mr-50"
                      />
                      <span class="font-weight-bold">{{ kFormatter(blog.comments_count) }}</span>
                    </div>
                  </b-link>
                  <b-link class="mr-1">
                    <div
                      class="d-flex align-items-center text-body"
                      @click="likeClicked(blog)"
                    >
                      <feather-icon
                        icon="ThumbsUpIcon"
                        class="mr-50"
                      />
                      <span class="font-weight-bold">{{ kFormatter(blog.likes ? blog.likes : 0) }}</span>
                    </div>
                  </b-link>
                  <b-link class="mr-1">
                    <div
                      class="d-flex align-items-center text-body"
                      @click="shareClicked(blog)"
                    >
                      <feather-icon
                        icon="Share2Icon"
                        class="mr-50"
                      />
                      <span class="font-weight-bold">{{ kFormatter(blog.shares) }}</span>
                    </div>
                  </b-link>
                </div>
                <b-link
                  :to="{ path: getViewUrl(blog) }"
                  class="font-weight-bold"
                >
                  {{ $t('Read More') }}
                </b-link>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          v-if="showPagination"
          cols="12"
        >
          <!-- pagination -->
          <div class="my-2">
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="rows"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>

      <!--/ blogs -->

      <!-- sidebar -->
      <div
        slot="sidebar"
        class="blog-sidebar py-2 py-lg-0"
      >
        <!-- input search -->
        <b-form-group class="blog-search">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="search-input"
              v-model="search_query"
              placeholder="Search here"
            />
            <b-input-group-append
              class="cursor-pointer"
              is-text
            >
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!--/ input search -->

        <!-- recent posts -->
        <div class="blog-recent-posts mt-1">
          <h6 class="section-label mb-75">
            {{ $t('Recent Posts') }}
          </h6>
          <b-media
            v-for="(recentpost, index) in blogRecentPost"
            :key="index"
            no-body
            :class="index ? 'mt-2' : ''"
          >
            <b-media-aside class="mr-2">
              <b-link :to="{ path: getViewUrl(recentpost) }">
                <b-img
                  :src="$helpers.imageHelper(recentpost.picture)"
                  width="100"
                  rounded
                  height="70"
                  @error="$helpers.imageError"
                />
              </b-link>
            </b-media-aside>
            <b-media-body>
              <h6 class="blog-recent-post-title">
                <b-link
                  :to="{ path: getViewUrl(recentpost) }"
                  class="text-body-heading"
                >
                  {{ recentpost.title }}
                </b-link>
              </h6>
            </b-media-body>
          </b-media>
        </div>
        <!--/ recent posts -->
      </div>
      <!--/ sidebar -->
    </content-with-sidebar>
    <landing-page-footer />
    <AppFooterBlack />
  </div>
</template>

<script>
import moment from 'moment'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import { useUserUi } from '../users/useUser'

export default {
  components: {
    ContentWithSidebar,
    LandingPageHeader,
    LandingPageFooter,
    AppFooterBlack,
  },
  data() {
    return {
      showPagination: false,
      search_query: '',
      blogList: [],
      blogRecentPost: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 1,
      rows: 140,
    }
  },
  created() {
    // this.blogList = data.blogList
    // this.blogSidebar = data.blogSidebar
  },
  mounted() {
    this.getBlogList()
  },
  methods: {
    likeClicked(blog) {
      const { likeBlog } = useUserUi()
      showLoader()
      likeBlog(blog.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (!blog.likes) {
              // eslint-disable-next-line no-param-reassign
              blog.likes = 0
            }
            // eslint-disable-next-line no-param-reassign
            blog.likes += 1
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    shareClicked(blog) {
      window.SuperApp.actions.sharePage('MOM', blog.title, this.getViewUrl(blog))
      const { shareBlog } = useUserUi()
      shareBlog(blog.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            // eslint-disable-next-line no-param-reassign
            blog.shares += 1
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm A')
    },
    kFormatter,
    tagsColor() {
      return 'light-primary'
    },
    getViewUrl(blog) {
      return `/blog/${blog.title
        .trim()
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^a-zA-Z- ]/g, '')}/${blog.id}`
    },
    getBlogList() {
      const { getAllBlogs } = useUserUi()
      showLoader()
      getAllBlogs()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.blogList = data.responseData
            this.blogRecentPost = window.SuperApp.actions.cloneDeep(this.blogList).reverse().slice(0, 10)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
.blog .cws-container .cws-sidebar {
  top: 10rem;
  right: 15px;
}
</style>

<style scoped>
.landing-page-wrapper .content-info {
  padding-top: 8rem;
  padding-left: 10px;
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing-page.scss';
</style>

<style lang="scss" scoped>
.articles-wrapper {
  height: 95%;
}

.tags-wrapper-blogs .badge-pill {
  margin-bottom: 5px;
}

.blog .blog-image-height {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .blog .blog-image-height {
    height: auto;
  }
}
</style>
